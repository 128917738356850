<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Management Member</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
          </div>
          <form @submit.prevent="updateMember">
            <div class="row">
              <div class="col-6">
                <div class="card">
                  <div class="card-header">
                    <h4>Profile</h4>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.name"
                        disabled
                      />
                      <small class="text-danger" v-if="errors.name">{{
                        errors.name[0]
                      }}</small>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label>Telp</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.telp"
                          disabled
                        />
                      </div>
                      <div class="form-group col-6">
                        <label>E-mail</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.email"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>ID User</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.IDuser"
                        disabled
                      />
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-6">
                        <label>Loyalty</label>
                        <h4>Rp. {{ formatPrice(form.loyalty) }}</h4>
                      </div>
                      <div class="col-6">
                        <label>Expired At</label>
                        <h4>{{ form.ext_loyalty }}</h4>
                      </div>
                      <div class="col-6">
                        <label>Is Repeated</label>
                        <h4>{{ form.loyalty_repeated ? "Yes" : "No" }}</h4>
                      </div>
                      <div class="col-6">
                        <label>Loyalty Limit</label>
                        <h4>Rp. {{ formatPrice(form.loyalty_limit) }}</h4>
                      </div>
                    </div>
                    <hr />
                    <label>Point</label>
                    <h4>Rp. {{ formatPrice(form.point) }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <loyalty-form></loyalty-form>
                <point-form></point-form>
              </div>
            </div>
            <div class="row">
              <div class="card col-md-6">
                <history-point
                  :historyPoint="form.history_point"
                ></history-point>
              </div>
              <div class="card col-md-6">
                <history-loyalty :historyLoyalty="form.history_loyalty" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { apiGet, apiPost } from "../../services/api";
import HistoryLoyalty from "./HistoryLoyalty.vue";
import HistoryPoint from "./HistoryPoint.vue";
import LoyaltyForm from "./LoyaltyForm.vue";
import PointForm from "./PointForm.vue";

export default {
  name: "ManagementMemberEdit",
  components: {
    HistoryLoyalty,
    HistoryPoint,
    LoyaltyForm,
    PointForm,
  },
  data() {
    return {
      name: "Management Member Edit",
      form: {
        name: "",
        history_loyalty: [],
      },
      itemsPerPage: 10,
      page: 1,
      search: "",
      loading: false,
      isSubmitting: false,
      errors: {},
      edit: false,
      listRepeated: [],
    };
  },
  mounted() {
    this.getData();
    this.getListRepeated();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("get_user_application/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateMember() {
      this.isSubmitting = true;
      apiPost("get_user_application/update/" + this.$route.params.id, this.data)
        .then(() => {
          this.getData();
          this.isSubmitting = false;
          this.$noty.success("Data successfully added!");
          this.data = {
            loyalty_repeated: "0",
            loyalty_limit: 0,
            ext_loyalty: "",
            status_repeated: "",
          };
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
