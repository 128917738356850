<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>Form Point</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Amount</label>
          <money v-model="form.amount" class="form-control"></money>
          <small class="text-danger" v-if="errors.amount">{{
            errors.amount[0]
          }}</small>
        </div>
        <div class="form-group">
          <label>Status</label>
          <select class="form-control" v-model="form.status">
            <option value="">Status Point</option>
            <option value="0">Pengurangan</option>
            <option value="1">Penambahan</option>
          </select>
          <small class="text-danger" v-if="errors.status">{{
            errors.status[0]
          }}</small>
        </div>
        <div class="form-group">
          <label>Information</label>
          <textarea class="form-control" v-model="form.information"></textarea>
          <small class="text-danger" v-if="errors.information">{{
            errors.information[0]
          }}</small>
        </div>
        <div class="form-group text-right">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="isSubmitting"
            @click="updateMember()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiPost } from "../../services/api";
export default {
  data() {
    return {
      isSubmitting: false,
      listRepeated: [],
      form: {
        amount: 0,
        status: "",
        information: "",
        ext_loyalty: "",
      },
      errors: [],
    };
  },
  methods: {
    updateMember() {
      this.isSubmitting = true;
      apiPost(
        "get_user_application/store/point/" + this.$route.params.id,
        this.form
      )
        .then(() => {
          this.$parent.getData();
          this.isSubmitting = false;
          this.$noty.success("Data successfully added!");
          this.form = {
            amount: 0,
            status: "",
            information: "",
          };
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
